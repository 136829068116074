import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Box } from '@mui/material';
import LoginModals from '../../shared/modal-dialogs/login-modals';
const Header: any = dynamic(() => import('./Header'), {
  ssr: false
});
const Footer: any = dynamic(() => import('./Footer'), {
  ssr: false
});
type Props = {
  children?: React.ReactNode;
};
export default function MainLayout({
  children
}: Props) {
  const isHome = false;
  return <div data-sentry-component="MainLayout" data-sentry-source-file="MainLayout.tsx">
      <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: 1
    }} data-sentry-element="Box" data-sentry-source-file="MainLayout.tsx">
        <Header data-sentry-element="Header" data-sentry-source-file="MainLayout.tsx" />
        <Box component="main" sx={{
        flexGrow: 1,
        ...(!isHome && {
          pt: {
            xs: 8,
            md: 11
          }
        }),
        border: '0px solid red'
      }} data-sentry-element="Box" data-sentry-source-file="MainLayout.tsx">
          {children}
        </Box>
        <Footer data-sentry-element="Footer" data-sentry-source-file="MainLayout.tsx" />
      </Box>
      <LoginModals data-sentry-element="LoginModals" data-sentry-source-file="MainLayout.tsx" />
    </div>;
}